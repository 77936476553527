import {
  all,
  put,
  takeEvery,
  call,
  take,
  actionChannel,
} from "redux-saga/effects";
import { setErrorStatus, setSuccessStatus } from "../status/action";
import {
  takeoverCompliant,
  compliantClosure,
  addUpdatesToCompliant,
} from "../../Services/database";
import { getFileUrl, uploadCompliantProof } from "../../Services/storage";
import { isValidArray, isValidObject } from "../../Services/validators";
import { bucketNames } from "../../Utils/constants";
import store from "../store";

export const actionTypes = {
  SET_COMPLAINTS_DATA: "SET_COMPLAINTS_DATA",
  UPDATE_COMPLAINTS_DATA: "UPDATE_COMPLAINTS_DATA",
  FETCH_UPDATE_COMPLAINTS_ASSETS: "FETCH_UPDATE_COMPLAINTS_ASSETS",
  FETCH_COMPLAINTS_ASSETS: "FETCH_COMPLAINTS_ASSETS",
  FIX_COMPLAINTS: "FIX_COMPLAINTS",
  COMPLAINT_TAKEOVER: "COMPLAINT_TAKEOVER",
  SET_FILTER: "SET_FILTER",
  TEST: "TEST",
};

function* setComplaintsWorker(action) {
  try {
    yield setComplaintsLoading(true);
    const existingComplaints = isValidObject(store.getState().complaints.data)
      ? JSON.parse(JSON.stringify(store.getState().complaints.data))
      : {};

    let updatedComplaints = action.payload.complaints;
    if (isValidObject(existingComplaints)) {
      Object.entries(existingComplaints).forEach(([id, data]) => {
        if (!action.payload.locationIds.includes(data.locationId)) {
          updatedComplaints[id] = data;
        }
      });
    } else {
      updatedComplaints = action.payload.complaints;
    }

    updatedComplaints = getValidComplaints(updatedComplaints);
    yield put({
      type: "SET_COMPLAINTS",
      payload: {
        data: updatedComplaints,
      },
    });
    yield setComplaintsLoading(false);

    for (let i = 0; i < Object.values(updatedComplaints).length; i++) {
      const assets = Object.values(updatedComplaints)[i].assets;
      let proof = {};
      let images = [];
      if (isValidArray(assets)) {
        for (let j = 0; j < assets.length; j++) {
          const file = yield getFileUrl(assets[j]);
          if (file.type === "image") {
            images.push(file.url);
          } else if (file.type === "audio") {
            proof = { ...proof, audio: file.url };
          }
        }

        yield put({
          type: "UPDATE_COMPLAINTS_WITH_ASSETS",
          payload: {
            complaintId: Object.values(updatedComplaints)[i].documentId,
            data: {
              ...Object.values(updatedComplaints)[i],
              proof: {
                ...proof,
                ...(isValidArray(images) ? { images: images } : {}),
              },
            },
          },
        });
      }
    }

    yield setComplaintsLoading(false);
  } catch (error) {
    yield setComplaintsLoading(false);
    setErrorStatus(error);
  }
}

function* fetchComplaintsAssetsWorker(action) {
  try {
    yield setComplaintsLoading(true);
    if (action.payload.type === "update") {
      const complaints = [];

      for (const complaint of Object.values(store.getState().complaints.data)) {
        if (
          store
            .getState()
            .profile.data?.connected?.location?.includes(
              complaint.locationId
            ) &&
          complaint.currentStatus !== "UNDER REVIEW"
        ) {
          complaints.push({ [complaint.documentId]: complaint });
        }
      }
      yield put({
        type: "SET_COMPLAINTS",
        payload: complaints,
      });

      yield setComplaintsLoading(false);

      for (let i = 0; i < Object.values(complaints).length; i++) {
        const assets = Object.values(complaints)[i].assets;
        let proof = {};
        let images = [];
        if (isValidArray(assets)) {
          for (let j = 0; j < assets.length; j++) {
            const file = yield getFileUrl(assets[j]);
            if (file.type === "image") {
              images.push(file.url);
            } else if (file.type === "audio") {
              proof = { ...proof, audio: file.url };
            }
          }

          yield put({
            type: "UPDATE_COMPLAINTS_WITH_ASSETS",
            payload: {
              complaintId: Object.values(complaints)[i].documentId,
              data: {
                ...Object.values(complaints)[i],
                proof: {
                  ...proof,
                  ...(isValidArray(images) ? { images: images } : {}),
                },
              },
            },
          });
        }
      }
    } else {
      yield put({
        type: "CLEAR_COMPLAINTS",
      });
    }

    yield setComplaintsLoading(false);
  } catch (error) {
    yield setComplaintsLoading(false);
    setErrorStatus(error);
  }
}

function* fetchUpdateComplaintAssetsWorker(action) {
  try {
    yield setComplaintsLoading(true);
    const updates =
      store.getState().complaints.data?.[action.payload.complaintId].updates;
    let newUpdate = [];
    for (let i = 0; i < updates.length; i++) {
      let assets = {};
      let images = [];
      for (let j = 0; j < updates[i].proof.length; j++) {
        const file = yield getFileUrl(updates[i].proof[j]);
        if (file.type === "image") {
          images.push(file.url);
        } else if (file.type === "audio") {
          assets = { ...assets, audio: file.url };
        }
      }
      newUpdate.push(
        (updates[i] = {
          ...updates[i],
          assets: {
            ...assets,
            ...(isValidArray(images) ? { images: images } : {}),
          },
        })
      );
    }

    yield put({
      type: "UPDATE_COMPLAINTS_WITH_ASSETS",
      payload: {
        complaintId: action.payload.complaintId,
        data: {
          ...store.getState().complaints.data?.[action.payload.complaintId],
          updates: newUpdate,
        },
      },
    });
    yield setComplaintsLoading(false);
  } catch (error) {
    yield setComplaintsLoading(false);
    setErrorStatus(error);
  }
}

function* fixComplaintsWorker(action) {
  try {
    yield setComplaintsLoading(true);

    let imageFileUrls = [];
    let audioFileUrl = [];

    if (isValidArray(action.payload.proof.image)) {
      for (let index = 0; index < action.payload.proof.image.length; index++) {
        yield uploadCompliantProof(
          action.payload.proof.image[index],
          action.payload.complaintId,
          action.payload.locationId
        );

        imageFileUrls.push(
          `${bucketNames.defaultBucket}/${action.payload.locationId}/complaints/${action.payload.complaintId}/closure/${action.payload.proof.image?.[index]?.name}`
        );
      }
    }

    if (action.payload.proof.audio) {
      yield uploadCompliantProof(
        action.payload.proof.audio,
        action.payload.complaintId,
        action.payload.locationId
      );
      audioFileUrl.push(
        `${bucketNames.defaultBucket}/${action.payload.locationId}/complaints/${action.payload.complaintId}/closure/${action.payload.proof.audio?.name}`
      );
    }

    yield compliantClosure(
      action.payload.complaintId,
      action.payload.employeeId,
      isValidArray(action.payload.proof.image) && action.payload.proof.audio
        ? [...imageFileUrls, ...audioFileUrl]
        : isValidArray(action.payload.proof.image)
        ? imageFileUrls
        : action.payload.proof.audio
        ? audioFileUrl
        : ""
    );

    setSuccessStatus("Submitted successfully");

    if (action.payload.navigate) {
      action.payload.navigate("/complaints");
    }

    yield setComplaintsLoading(false);
  } catch (error) {
    setErrorStatus(error);
    yield setComplaintsLoading(false);
  }
}

function* complaintTakeoverWorker(action) {
  try {
    yield setComplaintsLoading(true);

    yield takeoverCompliant(
      action.payload.complaintId,
      action.payload.employeeData
    );
    const complaintData = store.getState().complaints.data;
    yield put({
      type: "SET_COMPLAINTS",
      payload: {
        data: {
          ...complaintData,
          [action.payload.complaintId]: {
            ...complaintData?.[action.payload.complaintId],
            takenOverBy: action.payload.employeeData,
          },
        },
      },
    });

    setSuccessStatus("Compliant Taken over successfully");

    yield setComplaintsLoading(false);
  } catch (error) {
    console.error("complaintTakeoverWorker", error);
    setErrorStatus(error);
    yield setComplaintsLoading(false);
  }
}

function* updateComplaintsWorker(action) {
  try {
    yield setComplaintsLoading(true);

    let imageFileUrls = [];
    let audioFileUrl = [];

    if (isValidArray(action.payload.proof.image)) {
      for (let index = 0; index < action.payload.proof.image.length; index++) {
        yield uploadCompliantProof(
          action.payload.proof.image[index],
          action.payload.complaintId,
          action.payload.locationId
        );
        imageFileUrls.push(
          `${bucketNames.defaultBucket}/${action.payload.locationId}/complaints/${action.payload.complaintId}/closure/${action.payload.proof.image?.[index]?.name}`
        );
      }
    }

    if (action.payload.proof.audio) {
      yield uploadCompliantProof(
        action.payload.proof.audio,
        action.payload.complaintId,
        action.payload.locationId
      );
      audioFileUrl.push(
        `${bucketNames.defaultBucket}/${action.payload.locationId}/complaints/${action.payload.complaintId}/closure/${action.payload.proof.audio?.name}`
      );
    }

    yield addUpdatesToCompliant(
      action.payload.remarks,
      action.payload.complaintId,
      isValidArray(action.payload.proof.image) && action.payload.proof.audio
        ? [...imageFileUrls, ...audioFileUrl]
        : isValidArray(action.payload.proof.image)
        ? imageFileUrls
        : action.payload.proof.audio
        ? audioFileUrl
        : ""
    );

    setSuccessStatus("Update added successfully");

    yield setComplaintsLoading(false);
  } catch (error) {
    console.error("updateComplaintsWorker", error);
    setErrorStatus(error);
    yield setComplaintsLoading(false);
  }
}

function* setFilterWorker(action) {
  try {
    yield put({
      type: "PUT_FILTER",
      payload: { data: action.payload.data },
    });
  } catch (error) {
    console.error("setFilterWorker", error);
    setErrorStatus(error);
    yield setComplaintsLoading(false);
  }
}

export default function* complaintWatcher() {
  yield all([
    takeEvery("FIX_COMPLAINTS", fixComplaintsWorker),
    takeEvery("UPDATE_COMPLAINTS_DATA", updateComplaintsWorker),
    takeEvery("FETCH_COMPLAINTS_ASSETS", fetchComplaintsAssetsWorker),
    takeEvery(
      "FETCH_UPDATE_COMPLAINTS_ASSETS",
      fetchUpdateComplaintAssetsWorker
    ),

    takeEvery("COMPLAINT_TAKEOVER", complaintTakeoverWorker),
    takeEvery("SET_FILTER", setFilterWorker),
  ]);

  const setComplaintsChannel = yield actionChannel("SET_COMPLAINTS_DATA");

  while (true) {
    const setComplaintsChannelAction = yield take(setComplaintsChannel);
    yield call(setComplaintsWorker, setComplaintsChannelAction);
  }
}

function* setComplaintsLoading(bool) {
  yield put({
    type: "SET_COMPLAINTS_LOADING",
    payload: {
      loading: bool,
    },
  });
}
const isValidComplaint = (data) => {
  if (!isValidObject(data.takenOverBy)) {
    return true;
  } else {
    if (
      data.takenOverBy.phoneNumber === store.getState().auth.data.phoneNumber
    ) {
      return true;
    } else {
      return false;
    }
  }
};

const getValidComplaints = (complaints) => {
  const validComplaints = {};
  for (const key in complaints) {
    if (isValidComplaint(complaints[key])) {
      validComplaints[key] = complaints[key];
    }
  }
  return validComplaints;
};
