import React, { useState, useEffect } from "react";
import NavBar from "../../Components/NavBar/NavBar";
import Header from "../../Components/Header/Header";
import "./Complaints.css";
import {
  ArrowIcon,
  BackArrowIcon,
  ClockIcon,
  CloseIcon,
  FallbackClipArt,
  FilterIcon,
  ImageFallbackIcon,
  SearchIcon,
  TickIcon,
} from "../../Assets/assets";
import { connect } from "react-redux";
import { isValidArray, isValidObject } from "../../Services/validators";
import Button from "../../Components/Button/Button";
import { setFilter } from "../../Redux/complaints/action";
import SearchFallBackIcon from "../../Assets/SearchFallBackIcon.svg";

function Complaints(props) {
  const [complaints, setComplaints] = useState(null);
  const [sortBy, setSortBy] = useState("NEW");
  const [showFilter, setShowFilter] = useState(false);
  const [selectedFilterKey, setSelectedFilterKey] = useState("");
  const [issuerData, setIssuerData] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState({
    category: [],
    dateRange: {
      from: null,
      to: null,
    },
    issueRaised: [],
    pctNo: [],
  });

  const [appliedFilter, setAppliedFilter] = useState(null);
  const [searchKey, setSearchKey] = useState("");

  useEffect(() => {
    setAppliedFilter(props.complaints.filter);
    // eslint-disable-next-line
  }, [props.complaints.filter]);

  const getPctNUmberData = (data) => {
    if (data && isValidObject(data)) {
      return getLocationData(
        getUniqueValues(Object.values(data), "locationId")
      );
    } else {
      return [];
    }
  };

  useEffect(() => {
    if (isValidObject(props.complaints.data)) {
      if (searchKey?.length > 0) {
        let data = Object.values(props.complaints.data)?.filter((complaint) =>
          complaint.documentId
            ?.toString()
            .toLowerCase()
            .includes(searchKey.toString().toLowerCase())
        );
        setComplaints(sortArray(data, sortBy));
      } else if (appliedFilter && isFilterApplied(appliedFilter)) {
        let data = Object.values(props.complaints.data)?.filter((item) => {
          // Date range filter
          if (
            appliedFilter.dateRange?.from !== null ||
            appliedFilter.dateRange?.to !== null
          ) {
            const fromDate = +new Date(
              `${appliedFilter.dateRange.from}:00:00:00`
            );
            const toDate = +new Date(`${appliedFilter.dateRange.to}:23:59:59`);

            if (!(item.createdAt >= fromDate && item.createdAt <= toDate)) {
              return false;
            }
          }

          // Category filter
          if (
            appliedFilter?.category?.length > 0 &&
            !appliedFilter?.category.includes(item.category)
          ) {
            return false;
          }

          // Check issueRaised filter
          if (appliedFilter.issueRaised?.length > 0) {
            let matchFound = false;
            appliedFilter.issueRaised.forEach((value) => {
              if (
                item.issuedBy?.userDetails &&
                value === item.issuedBy?.userDetails?.phoneNumber
              ) {
                matchFound = true;
              } else if (
                item.issuedBy?.sensorDetails &&
                value === item.issuedBy?.sensorDetails.name
              ) {
                matchFound = true;
              }
            });
            if (!matchFound) {
              return false;
            }
          }

          // PCT NO filter
          if (
            appliedFilter?.pctNo?.length > 0 &&
            !appliedFilter?.pctNo.includes(
              `${getLocationData([item.locationId])?.[0]?.PCTNo} - ${
                getLocationData([item.locationId])?.[0]?.location
              }`
            )
          ) {
            return false;
          }

          return true;
        });

        setComplaints(sortArray(data, sortBy));
      } else {
        setComplaints(sortArray(Object.values(props.complaints.data), sortBy));
      }
    }
    // eslint-disable-next-line
  }, [props.complaints.data, appliedFilter, sortBy, searchKey]);

  useEffect(() => {
    if (props.complaints.data) {
      let userData = {};
      Object.values(props.complaints.data)?.forEach((data) => {
        if (data.issuedBy?.userDetails) {
          userData = {
            ...userData,
            [data.issuedBy?.userDetails?.phoneNumber || "notProvided"]:
              data.issuedBy.userDetails,
          };
        } else if (data.issuedBy?.sensorDetails) {
          userData = {
            ...userData,
            [data.issuedBy?.sensorDetails?.name || "notProvided"]:
              data.issuedBy.sensorDetails,
          };
        }
      });
      setIssuerData(Object.values(userData));
    }

    // eslint-disable-next-line
  }, [props.complaints.data]);

  function getUniqueValues(data, type) {
    if (type) {
      const values = data?.map((item) => item?.[type]);
      return Array.from(new Set(values));
    }
  }

  const getLocationData = (locationIds) => {
    let locationData = [];

    for (let i = 0; i < locationIds?.length; i++) {
      if (locationIds?.[i]) {
        locationData.push(props.locations.data?.[locationIds?.[i]]);
      }
    }
    return locationData;
  };

  const filterData = {
    category: {
      title: "Category",
      value:
        props.complaints?.data &&
        getUniqueValues(Object.values(props.complaints?.data), "category"),
    },
    issueRaised: {
      title: "Issue Raised",
    },
    dateRange: {
      title: "Date Range",
      value: {
        from: null,
        to: null,
      },
    },
    pctNo: {
      title: "PCT No",
      value: getPctNUmberData(props.complaints.data),
    },
  };

  function resetFilter() {
    let data = {
      category: [],
      dateRange: {
        from: null,
        to: null,
      },
      issueRaised: [],
      pctNo: [],
    };
    setSelectedFilter(data);
    props.setFilter(data);
  }

  const isFilterApplied = (filterData) => {
    if (
      filterData?.category?.length > 0 ||
      filterData?.dateRange?.from !== null ||
      filterData?.dateRange?.to !== null ||
      filterData?.issueRaised?.length > 0 ||
      filterData?.pctNo?.length > 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="inherit-parent-height overflow-hidden">
      <Header
        title={showFilter ? "Filter" : "Complaints"}
        backOnClick={
          showFilter
            ? () => {
                setShowFilter(false);
              }
            : ""
        }
      />
      {!showFilter ? (
        <>
          <div className="display-flex flex-justify-content-center flex-align-items-center border-bottom-1px margin-right-large margin-left-large margin-top-default">
            <input
              style={{
                width: "100%",
              }}
              className={` inherit-parent-height border-none font-family-RHD-regular font-size-large padding-default inherit-parent-width`}
              value={searchKey}
              autoFocus={true}
              onChange={(event) => {
                setSearchKey(event.target.value);
              }}
              placeholder="Complaint ID"
              title="Search bar"
              data-cy="search-input-field"
            />
            <button
              className="padding-right-default background-color-transparent"
              type={"button"}
              data-cy={
                searchKey?.length > 0 ? "search-clear-button" : "search-button"
              }
              onClick={() => {
                if (searchKey?.length > 0) {
                  setSearchKey("");
                }
              }}
            >
              {searchKey?.length < 1 ? (
                <SearchIcon />
              ) : (
                <CloseIcon width={20} height={20} />
              )}
            </button>
          </div>
          <div className=" display-flex flex-align-items-center flex-justify-content-space-between padding-large">
            <div
              className="display-flex flex-align-items-center "
              data-cy={sortBy === "NEW" ? "filter-oldest" : "filter-newest"}
              onClick={() => {
                if (sortBy === "NEW") {
                  setSortBy("OLD");
                } else {
                  setSortBy("NEW");
                }
              }}
            >
              <div className="padding-right-default">
                {sortBy === "NEW" ? "Oldest" : "Newest"}
              </div>
              <div
                className={` display-flex flex-align-items-center  ${
                  sortBy === "NEW" ? "rotate-90" : "rotate-270"
                } `}
              >
                <BackArrowIcon />
              </div>
            </div>

            <div
              className="display-flex flex-align-items-center position-relative "
              data-cy={"complaints-filter-button"}
              onClick={() => {
                setShowFilter(true);
                setSelectedFilter(appliedFilter);
              }}
            >
              <div className=" display-flex flex-align-items-center padding-right-medium">
                Filter
              </div>
              <FilterIcon />
              {isFilterApplied(appliedFilter) && (
                <div
                  style={{
                    height: "8px",
                    width: "8px",
                    top: "2px",
                    right: "0px",
                  }}
                  className="background-color-primary border-radius-16px position-absolute"
                />
              )}
            </div>
          </div>
          {isFilterApplied(appliedFilter) && complaints?.length === 0 && (
            <div className=" inherit-parent-width remaining-body-height_-229px flex-justify-content-center flex-align-items-center flex-direction-column ">
              <img src={SearchFallBackIcon} alt="no filter result found" />
              <div className=" padding-top-larger">NO MATCHING RESULTS</div>
              <div className=" padding-top-large">
                There wasn’t any result matching to the search and filter
              </div>
            </div>
          )}

          <div className=" inherit-parent-width remaining-body-height_-229px flex-justify-content-center ">
            <div className="inherit-parent-height inherit-parent-width max-width-767px overflow-x-scroll hide-scrollbar flex-justify-content-center">
              {!props.complaints.loading &&
                !props.locations.loading &&
                isValidArray(complaints) &&
                isValidObject(props.locations.data) && (
                  <div className="inherit-parent-width inherit-parent-height">
                    <div
                      className="padding-left-large padding-right-large padding-bottom-large inherit-parent-width inherit-parent-height"
                      data-cy="complaints-list"
                    >
                      {complaints.map((data, index) => (
                        <div className="inherit-parent-width" key={index}>
                          <Card
                            onClick={() => {
                              props.navigate(`/complaints/${data.documentId}`);
                            }}
                            index={index}
                            data={data}
                            locations={props.locations?.data}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                )}

              {!props.complaints.loading &&
                !isValidObject(props.complaints.data) && (
                  <NoComplaintsFallback />
                )}

              {props.complaints.loading ||
                (props.locations.loading && <Suspense />)}
            </div>
            <div className=" position-absolute bottom-0 inherit-parent-width">
              <NavBar navigate={props.navigate} />
            </div>
          </div>
        </>
      ) : (
        <div
          className="remaining-body-height"
          data-cy="complaints-filter-screen"
        >
          <div
            style={{ height: "calc(100% - 76px)" }}
            className="inherit-parent-width  padding-left-large padding-right-large padding-bottom-large overflow-scroll "
          >
            {Object.keys(filterData).map((data, index) => (
              <React.Fragment key={index}>
                <Accordion
                  filterKey={data}
                  items={props.items}
                  selectedFilter={selectedFilter}
                  setSelectedFilter={setSelectedFilter}
                  setSelectedFilterKey={setSelectedFilterKey}
                  selectedFilterKey={selectedFilterKey}
                  issuerData={issuerData}
                  data={filterData[data]}
                  complaints={
                    props.complaints.data &&
                    Object.values(props.complaints.data)
                  }
                  theme={props.theme}
                />
              </React.Fragment>
            ))}
          </div>

          <div className=" display-flex padding-large">
            <Button
              type="button"
              className=" max-width-500px margin-right-default"
              data-cy={`apply-filter-button`}
              text={"Apply"}
              onClick={() => {
                props.setFilter(selectedFilter);
                setShowFilter(false);
              }}
              disabled={
                selectedFilter.dateRange.from >
                  new Date().toISOString().split("T")[0] ||
                selectedFilter.dateRange.to < selectedFilter.dateRange.from ||
                selectedFilter.dateRange.to >
                  new Date().toISOString().split("T")[0]
              }
              boxShadow={false}
            />

            <Button
              type="button"
              variant="secondary"
              className=" max-width-500px margin-left-default"
              data-cy={`reset-filter-button`}
              text={"Reset"}
              onClick={() => {
                resetFilter();
                setShowFilter(false);
              }}
              boxShadow={false}
            />
          </div>
        </div>
      )}
    </div>
  );
}

const Card = (props) => {
  return (
    <div
      onClick={props.onClick}
      className="display-flex inherit-parent-width padding-bottom-large"
      data-cy={`complaint-card-${props.data.documentId}`}
    >
      {isValidObject(props.data.proof) &&
      isValidArray(props.data.proof.images) ? (
        <img
          data-cy={`loaded-complaint-image-${props.data.documentId}`}
          className="height-width-56px border-radius-default inherit-parent-height inherit-parent-width border-radius-default margin-right-default"
          src={props.data.proof.images[0]}
          alt="images"
        />
      ) : (
        <div className="height-fit-content width-fit-content border-radius-default margin-right-default">
          <div className="height-width-56px border-radius-default inherit-parent-height inherit-parent-width background-color-grey display-flex flex-justify-content-center flex-align-items-center">
            <ImageFallbackIcon />
          </div>
        </div>
      )}

      <div className="parent-width_-68px display-flex flex-justify-content-space-between">
        <div className=" parent-width_-68px">
          {props.locations?.[props.data.location.id]?.location ? (
            <>
              <div className=" font-size-medium font-family-RHD-medium padding-bottom-small inherit-parent-width text-overflow-ellipsis">
                {props.data.category}
              </div>
              <div className="font-size-small">
                {`${props.locations?.[
                  props.data.location.id
                ]?.PCTNo?.toString().padStart(2, "0")}
              - ${props.locations?.[props.data.location.id]?.location}`}
              </div>
              <div className="font-size-small padding-top-small width-90-percentage text-overflow-ellipsis">
                {props.data.documentId}
              </div>
            </>
          ) : (
            <div className="padding-default inherit-parent-width">
              <div className="shimmer padding-larger border-radius-default inherit-parent-width" />
            </div>
          )}
        </div>

        <div className=" display-flex flex-align-items-center ">
          <ClockIcon />
          <div className="font-size-smaller padding-left-small white-space-nowrap padding-right-small">
            {getDays(props.data.createdAt)}
          </div>

          <div className=" display-flex flex-align-items-center ">
            <ArrowIcon />
          </div>
        </div>
      </div>
    </div>
  );
};

const NoComplaintsFallback = () => {
  return (
    <div
      className=" inherit-parent-height padding-horizontal-large display-flex flex-direction-column flex-justify-content-center flex-align-items-center"
      data-cy="no-complaints-fallback"
    >
      <FallbackClipArt />
      <div className=" padding-top-larger font-family-RHD-medium">
        No complaints yet
      </div>
      <div className=" padding-top-medium text-align-center font-size-medium">
        Please check after sometime.
      </div>
    </div>
  );
};

const mapStateToProps = function (state) {
  return {
    complaints: state.complaints,
    locations: state.locations,
  };
};

const mapDispatchToProps = function () {
  return { setFilter: (data) => setFilter(data) };
};

export default connect(mapStateToProps, mapDispatchToProps)(Complaints);

const getDays = (timestamp) => {
  const days = Math.round((+new Date() - timestamp) / 86400000);
  const hours = Math.round((+new Date() - timestamp) / 3600000);
  const minutes = Math.round((+new Date() - timestamp) / 60000);

  if (days >= 1) {
    return `${days} DAY${days !== 1 ? "S" : ""}`;
  } else if (hours >= 1) {
    return `${hours} HR${hours !== 1 ? "S" : ""}`;
  } else if (minutes >= 1) {
    return `${minutes} MIN${minutes !== 1 ? "S" : ""}`;
  } else {
    return "less than min";
  }
};

const Suspense = () => {
  return (
    <div className=" inherit-parent-width padding-top-large">
      {[...Array(7)].map((_data, index) => (
        <div className=" padding-default inherit-parent-width" key={index}>
          <div className="shimmer padding-larger border-radius-default  inherit-parent-width" />
        </div>
      ))}
    </div>
  );
};

const Accordion = (props) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <div
        className={`display-flex flex-justify-content-space-between  padding-top-large padding-bottom-large ${
          show ? "" : "border-bottom-1px"
        } position-sticky top-0 background-white `}
        data-cy={`${props.filterKey}`}
        onClick={() => {
          if (show === false) {
            setShow(true);
          } else {
            setShow(false);
          }
          props.setSelectedFilterKey(props.filterKey);
        }}
      >
        <div
          className={`${
            show ? " " : ""
          } font-size-default font-family-RHD-medium`}
        >
          {props.data.title}
        </div>
        <DropDownButton
          dropDownButton-data-cy="title-dropDown"
          dropDownClick={() => {
            if (show === false) {
              setShow(true);
            } else {
              setShow(false);
            }
          }}
          showData={show}
          theme={props.theme}
        />
      </div>

      {show && (
        <div className="border-bottom-1px ">
          {props.filterKey === "category" &&
            getUniqueDataFromArray(props.filterKey, props.complaints)?.map(
              (item, index) => (
                <div
                  key={index}
                  data-cy={`${
                    props.selectedFilter.category.includes(item)
                      ? "selected-"
                      : ""
                  }${props.filterKey}-${item}`}
                  onClick={() => {
                    if (props.selectedFilter.category.includes(item)) {
                      props.setSelectedFilter({
                        ...props.selectedFilter,
                        category: props.selectedFilter.category.filter(
                          (data) => data !== item
                        ),
                      });
                    } else {
                      props.setSelectedFilter({
                        ...props.selectedFilter,
                        category: [...props.selectedFilter.category, item],
                      });
                    }
                  }}
                  className="padding-top-medium padding-bottom-medium display-flex flex-align-items-center cursor-pointer"
                >
                  <div className="display-flex font-size-larger flex-justify-content-center flex-align-items-center">
                    {props.selectedFilter.category.includes(item) ? (
                      <div className="margin-right-large">
                        <TickIcon
                          color={props.theme === "dark" ? "white" : "#404040"}
                          height={16}
                          width={16}
                        />
                      </div>
                    ) : (
                      <div className="margin-right-large">
                        <TickIcon
                          color={"rgba(0, 0, 0, 0)"}
                          height={16}
                          width={16}
                        />
                      </div>
                    )}
                    <div className="font-size-default">
                      {item}
                      <span className="padding-left-large">
                        (
                        {`${getTotalValueCount(
                          props.complaints,
                          "category",
                          item
                        )}`}
                        )
                      </span>
                    </div>
                  </div>
                </div>
              )
            )}
          {props.filterKey === "issueRaised" &&
            isValidArray(props.issuerData) &&
            props.issuerData?.map((data, index) => (
              <div
                key={index}
                className="padding-top-medium padding-bottom-medium inherit-parent-width"
              >
                <EmployeeCard
                  data-cy={`${data?.phoneNumber}-card`}
                  data={data}
                  selected={
                    data?.phoneNumber
                      ? props.selectedFilter?.issueRaised.includes(
                          data?.phoneNumber
                        )
                      : props.selectedFilter?.issueRaised.includes(data?.name)
                  }
                  count={() => {
                    let count = 0;
                    props.complaints.forEach((Object) => {
                      if (
                        Object.issuedBy?.userDetails?.phoneNumber ===
                        data?.phoneNumber
                      ) {
                        count++;
                      }
                    });
                    return count;
                  }}
                  cardOnclick={() => {
                    if (
                      !props.selectedFilter.issueRaised.includes(
                        data?.phoneNumber || data?.name
                      )
                    ) {
                      props.setSelectedFilter({
                        ...props.selectedFilter,
                        issueRaised: [
                          ...props.selectedFilter.issueRaised,
                          data?.phoneNumber || data?.name,
                        ],
                      });
                    } else {
                      props.setSelectedFilter({
                        ...props.selectedFilter,
                        issueRaised: data.phoneNumber
                          ? props.selectedFilter.issueRaised.filter(
                              (value) => value !== data.phoneNumber
                            )
                          : props.selectedFilter.issueRaised.filter(
                              (value) => value !== data.name
                            ),
                      });
                    }
                  }}
                />
              </div>
            ))}
          {props.filterKey === "dateRange" && (
            <form
              className="padding-left-larger padding-right-larger padding-top-medium font-size-default font-family-RHD-medium"
              data-cy={"select-date"}
              onChange={(e) => {
                props.setSelectedFilter({
                  ...props.selectedFilter,
                  dateRange: {
                    ...props.selectedFilter.dateRange,
                    [e.target.name]: e.target.value,
                    ...(e.target.name === "from" &&
                    !props.selectedFilter.dateRange?.to
                      ? { to: new Date().toISOString().split("T")[0] }
                      : {}),
                    ...(e.target.name === "to" &&
                    !props.selectedFilter.dateRange?.from
                      ? { from: e.target.value }
                      : {}),
                  },
                });
              }}
            >
              <div className="display-flex flex-justify-content-center">
                <div style={{ width: 70 }} className="  padding-top-smaller">
                  FROM
                </div>
                <div className="display-flex flex-direction-column inherit-parent-width">
                  <input
                    name="from"
                    data-cy={"date-range-from"}
                    className={` ${
                      props.theme === "dark"
                        ? "font-color-white background-color-dark calendar-icon-color-invert"
                        : "font-color-secondary"
                    }  border-none inherit-parent-width font-size-default font-family-RHD-medium `}
                    value={props.selectedFilter.dateRange.from || ""}
                    max={
                      props.selectedFilter.dateRange?.to ||
                      new Date().toISOString().split("T")[0]
                    }
                    type="date"
                  />
                  <div
                    style={{ height: "25px" }}
                    className="font-color-red padding-top-small font-size-default"
                  >
                    {props.selectedFilter.dateRange.from >
                    new Date().toISOString().split("T")[0]
                      ? "Please enter a valid date"
                      : ""}
                  </div>
                </div>
              </div>

              <div className="display-flex flex-justify-content-center padding-top-large">
                <div style={{ width: 70 }} className=" padding-top-smaller">
                  TO
                </div>
                <div className="display-flex flex-direction-column inherit-parent-width ">
                  <input
                    name="to"
                    data-cy={"date-range-to"}
                    className={` ${
                      props.theme === "dark"
                        ? "font-color-white background-color-dark calendar-icon-color-invert"
                        : "font-color-secondary"
                    }  border-none inherit-parent-width font-size-default font-family-RHD-medium `}
                    value={props.selectedFilter.dateRange.to || ""}
                    min={props.selectedFilter.dateRange?.from || ""}
                    max={new Date().toISOString().split("T")[0]}
                    type="date"
                  />

                  <div
                    style={{ height: "25px" }}
                    className="font-color-red padding-top-small font-size-default"
                  >
                    {props.selectedFilter.dateRange.to <
                      props.selectedFilter.dateRange.from ||
                    props.selectedFilter.dateRange.to >
                      new Date().toISOString().split("T")[0]
                      ? "Please enter a valid date"
                      : ""}
                  </div>
                </div>
              </div>
            </form>
          )}
          {props.filterKey === "pctNo" &&
            props.data.value?.map((ele, index) => (
              <div
                className="padding-top-medium padding-bottom-medium display-flex flex-align-items-center cursor-pointer"
                data-cy={`${
                  props.selectedFilter.pctNo?.includes(
                    `${ele.PCTNo} - ${ele.location}`
                  )
                    ? "selected-"
                    : ""
                }${props.filterKey}-${ele.PCTNo} - ${ele.location}`}
                onClick={() => {
                  if (
                    props.selectedFilter.pctNo?.includes(
                      `${ele.PCTNo} - ${ele.location}`
                    )
                  ) {
                    props.setSelectedFilter({
                      ...props.selectedFilter,
                      pctNo: props.selectedFilter.pctNo.filter(
                        (data) => data !== `${ele.PCTNo} - ${ele.location}`
                      ),
                    });
                  } else {
                    props.setSelectedFilter({
                      ...props.selectedFilter,
                      pctNo: [
                        ...props.selectedFilter.pctNo,
                        `${ele.PCTNo} - ${ele.location}`,
                      ],
                    });
                  }
                }}
                key={index}
              >
                <div className="display-flex font-size-larger flex-align-items-center">
                  {props.selectedFilter.pctNo.includes(
                    `${ele.PCTNo} - ${ele.location}`
                  ) ? (
                    <div className="margin-right-large">
                      <TickIcon
                        color={props.theme === "dark" ? "white" : "#404040"}
                        height={16}
                        width={16}
                      />
                    </div>
                  ) : (
                    <div className="margin-right-large">
                      <TickIcon
                        color={"rgba(0, 0, 0, 0)"}
                        height={16}
                        width={16}
                      />
                    </div>
                  )}
                  <div className="font-size-default">
                    {`${ele.PCTNo} - ${ele.location}`}
                    <span className="padding-left-large">
                      (
                      {`${getTotalValueCount(
                        props.complaints,
                        "locationId",
                        ele.documentId
                      )}`}
                      )
                    </span>
                  </div>
                </div>
              </div>
            ))}
        </div>
      )}
    </>
  );
};

const DropDownButton = (props) => {
  return (
    <div
      className={`${props.className} cursor-pointer`}
      onClick={props.dropDownClick}
      data-cy={props["dropDownButton-data-cy"]}
    >
      <div
        style={{ rotate: props.showData ? "270deg" : "90deg" }}
        className="flex-justify-content-center"
      >
        <ArrowIcon color={props.theme === "dark" ? "white" : "#404040"} />
      </div>
    </div>
  );
};

function getUniqueDataFromArray(value, data) {
  const result = [];

  for (let i = 0; i < data?.length; i++) {
    const item = data?.[i];
    if (!result.includes(item?.[value])) {
      result.push(item?.[value]);
    }
  }
  return result;
}

const getTotalValueCount = (data, type, value) => {
  let count = 0;

  data?.forEach((object) => {
    if (object?.[type] === value) {
      count++;
    }
  });

  return count;
};

export const EmployeeCard = (props) => {
  return (
    <div
      data-cy={props["data-cy"]}
      onClick={props.cardOnclick}
      className="display-flex cursor-pointer flex-justify-content-center flex-align-items-center inherit-parent-width"
    >
      {props.selected ? (
        <div className=" margin-right-large">
          <TickIcon
            color={props.theme === "dark" ? "white" : "#404040"}
            width={16}
            height={16}
          />
        </div>
      ) : (
        <div className="margin-right-large">
          <TickIcon color={"rgba(0, 0, 0, 0)"} width={16} height={16} />
        </div>
      )}
      <div className="display-flex flex-direction-column flex-justify-content-space-between inherit-parent-width">
        <div
          style={{
            width: "calc(100% - 100px)",
          }}
          className="text-uppercase font-family-RHD-medium font-size-default text-overflow-ellipsis"
        >
          {props.data?.name || "NOT PROVIDED"} ({props.count()})
        </div>
        <div className="text-capitalize padding-top-small font-size-small">
          {props.data?.phoneNumber || "NOT PROVIDED"}
        </div>
      </div>
    </div>
  );
};

function sortArray(data, type) {
  let sortedData;
  if (type === "NEW") {
    sortedData = Object.values(data)?.sort((first, second) => {
      return second.createdAt - first.createdAt;
    });
  } else {
    sortedData = Object.values(data)?.sort((first, second) => {
      return first.createdAt - second.createdAt;
    });
  }
  return sortedData;
}
