import { useEffect, useState } from "react";
import {
  query,
  collection,
  where,
  onSnapshot,
  doc,
  getDocs,
  updateDoc,
  getDoc,
  collectionGroup,
  writeBatch,
  arrayUnion,
} from "firebase/firestore";
import { database } from "./firebase";
import { setErrorStatus } from "../Redux/status/action";
import { setAccountProfiles } from "../Redux/profile/action";
import { isValidArray, isValidObject } from "./validators";
import {
  setComplaints,
  fetchCompliantAssets,
} from "../Redux/complaints/action";
import { uploadProcedureHistoryFiles } from "./storage";
import { checkIfArraysEqual, chunkArray } from "../Utils/constants";

export function useProfileListener(props) {
  const [profileListener, setProfileListener] = useState({
    listener: null,
  });

  const subscribeToProfile = (phoneNumber) => {
    if (phoneNumber) {
      const profileQuery = query(
        collection(database, "employees"),
        where("phoneNumber", "==", phoneNumber)
      );

      return onSnapshot(
        profileQuery,
        (dataSnapshot) => {
          let profiles = {};
          dataSnapshot.forEach((doc) => {
            profiles = {
              ...doc.data(),
            };
          });
          setAccountProfiles(profiles);
        },
        (error) => {
          console.error(error, "from profile");
          setErrorStatus(error);
        }
      );
    } else {
      return;
    }
  };
  useEffect(() => {
    if (
      props.isAuth === true &&
      typeof props.phoneNumber === "string" &&
      typeof props.uid === "string" &&
      profileListener.listener === null
    ) {
      setProfileListener({
        listener: subscribeToProfile(props.phoneNumber),
      });
    } else if (
      props.isAuth === false &&
      typeof profileListener.listener === "function"
    ) {
      profileListener.listener();
      setProfileListener({
        listener: null,
      });
    }
    // eslint-disable-next-line
  }, [props.isAuth, props.phoneNumber, props.uid]);
}

export function useComplaintsListener(props) {
  const [complaintsListener, setComplaintsListener] = useState({
    listener: null,
  });

  const subscribeToComplaints = (locationIds) => {
    if (locationIds) {
      const complaintsQuery = query(
        collection(database, "complaints"),
        where("location.id", "in", locationIds),
        where("status.currentStatus", "==", "OPEN")
      );

      return onSnapshot(
        complaintsQuery,
        (dataSnapshot) => {
          let complaints = {};
          dataSnapshot.forEach((doc) => {
            complaints[doc.id] = {
              ...doc.data(),
              documentId: doc.id,
            };
          });
          setComplaints(complaints, locationIds);
        },
        (error) => {
          console.error(error, "from complaints");
          setErrorStatus(error);
        }
      );
    }
  };

  useEffect(() => {
    if (props.isAuth === true && props.locationIds?.length === 0) {
      fetchCompliantAssets("clear");
      if (complaintsListener.listener !== null) {
        setComplaintsListener({
          listener: null,
        });
      }
    }

    if (
      props.isAuth === true &&
      isValidArray(props.locationIds) &&
      typeof props.uid === "string" &&
      complaintsListener.listener === null
    ) {
      let listener = {};

      const chunkedLocationIds = chunkArray(props.locationIds, 20);
      for (let index = 0; index < chunkedLocationIds.length; index++) {
        if (
          isValidObject(complaintsListener?.listener) &&
          complaintsListener.listener[index]
        ) {
          continue;
        }

        listener = {
          ...listener,
          ...{
            [index]: {
              function: subscribeToComplaints(
                chunkedLocationIds[index],
                props.isAuth
              ),
              locationIds: chunkedLocationIds[index],
            },
          },
        };
      }
      setComplaintsListener({ ...complaintsListener, listener });
    } else if (
      isValidArray(props.locationIds) &&
      isValidObject(complaintsListener.listener) &&
      !checkIfArraysEqual(
        Object.values(complaintsListener.listener)?.flatMap(
          (obj) => obj.locationIds
        ),
        props.locationIds
      )
    ) {
      for (const keys in complaintsListener.listener) {
        if (typeof complaintsListener.listener[keys].function === "function") {
          complaintsListener.listener[keys].function();
        }
      }
      setComplaintsListener({
        listener: "fetch",
      });
      fetchCompliantAssets("update");
    } else if (
      props.isAuth === false &&
      complaintsListener.listener !== null &&
      isValidArray(props.locationIds)
    ) {
      for (const keys in complaintsListener.listener) {
        if (typeof complaintsListener.listener[keys].function === "function") {
          complaintsListener.listener[keys].function();
        }
      }
      setComplaintsListener({
        listener: null,
      });
    }

    // eslint-disable-next-line
  }, [props.isAuth, props.locationIds, props.uid]);

  useEffect(() => {
    if (complaintsListener.listener === "fetch") {
      let listener = {};
      const chunkedLocationIds = chunkArray(props.locationIds, 20);
      for (let index = 0; index < chunkedLocationIds.length; index++) {
        if (
          isValidObject(complaintsListener?.listener) &&
          complaintsListener.listener[index]
        ) {
          continue;
        }
        listener = {
          ...listener,
          ...{
            [index]: {
              function: subscribeToComplaints(
                chunkedLocationIds[index],
                props.isAuth
              ),
              locationIds: chunkedLocationIds[index],
            },
          },
        };
      }
      setComplaintsListener({ ...complaintsListener, listener });
    }
    // eslint-disable-next-line
  }, [complaintsListener.listener]);
}

export async function getProceduresByProcedureIds(procedureIds) {
  let proceduresData = {};
  for (let i = 0; i < procedureIds.length; i++) {
    const rootRef = doc(database, "procedures", procedureIds[i]);
    const data = (await getDoc(rootRef)).data();
    proceduresData = {
      ...proceduresData,
      [procedureIds[i]]: { ...data, documentId: procedureIds[i] },
    };
  }
  return proceduresData;
}

export async function getProceduresHistory(
  procedureId,
  locationId,
  employeeId
) {
  const searchData = query(
    collectionGroup(database, "procedureHistory"),
    where("locationId", "==", locationId),
    where("procedureId", "==", procedureId),
    where("employeeId", "==", employeeId)
  );
  const querySnapshot = await getDocs(searchData);
  const obj = {};
  querySnapshot.docs.forEach((doc) => {
    obj[locationId] = {
      ...obj[locationId],
      [doc.id]: {
        ...doc.data(),
        documentId: doc.id,
      },
    };
  });
  return obj;
}

export async function getLocationsByLocationIds(locationIds) {
  let locationsData = {};
  for (let i = 0; i < locationIds.length; i++) {
    const rootRef = doc(database, "locations", locationIds[i]);
    const data = (await getDoc(rootRef)).data();
    locationsData = {
      ...locationsData,
      [locationIds[i]]: { ...data, documentId: locationIds[i] },
    };
  }
  return locationsData;
}

export async function setProcedureHistory(data, files) {
  const batch = writeBatch(database);
  const rootRef = doc(
    collection(database, "locations", data.locationId, "procedureHistory")
  );

  let latestUpdateData = [];
  for (let i = 0; i < files.length; i++) {
    let fileSrc = [];

    for (let j = 0; j < files[i].proof?.length; j++) {
      const fileName = files[i].proof[j].name
        ? files[i].proof[j].name
        : files[i].proof[j].size;
      if (typeof fileName === "string") {
        const filePath = await uploadProcedureHistoryFiles(
          `/${data.locationId}/procedures/${data.procedureId}/${rootRef.id}/${
            i + 1
          }/${fileName}`,
          files[i].proof[j]
        );
        fileSrc.push(filePath);
      }
    }
    latestUpdateData.push({
      proof: fileSrc,
      success: files[i].success,
      ...(files[i].remarks ? { remarks: files[i].remarks } : {}),
      ...(files[i].selectedKpi ? { kpi: files[i].selectedKpi } : {}),
      ...(files[i].selectedPenalty
        ? { penalty: files[i].selectedPenalty }
        : {}),
      ...(files[i].selectedRemarks
        ? {
            requiredRemarks: files[i].selectedRemarks,
          }
        : {}),
      title: files[i].title,
      description: files[i].description,
    });
  }

  const docRef = doc(
    database,
    "locations",
    data.locationId,
    "procedureHistory",
    rootRef.id
  );

  batch.set(docRef, { ...data, latestUpdate: latestUpdateData });
  await batch.commit();
}

export async function compliantClosure(compliantId, employeeId, proof) {
  const rootRef = doc(database, "complaints", compliantId);

  await updateDoc(rootRef, {
    status: { currentStatus: "UNDER REVIEW", updatedAt: +new Date() },
    closure: {
      resolvedAt: +new Date(),
      resolvedBy: employeeId,
      proof: proof,
    },
  });
}

export async function updateAccessSyncStatus(employeeId, status) {
  const rootRef = doc(database, "employees", employeeId);

  await updateDoc(rootRef, {
    accessSyncStatus: status,
  });
}

export async function addUpdatesToCompliant(remarks, compliantId, proof) {
  const rootRef = doc(database, "complaints", compliantId);

  await updateDoc(rootRef, {
    updates: arrayUnion({
      proof: proof,
      remarks: remarks,
      updatedAt: +new Date(),
    }),
  });
}

export async function takeoverCompliant(compliantId, employeeData) {
  const rootRef = doc(database, "complaints", compliantId);

  await updateDoc(rootRef, {
    takenOverBy: employeeData,
  });
}
