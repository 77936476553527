import React, { useState, useEffect } from "react";
import Header from "../../Components/Header/Header";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import Button from "../../Components/Button/Button";
import FileUploadModal from "../../Components/FileUploadModal/FileUploadModal";
import CropperModal from "../../Components/CropperModal/CropperModal";
import Modal from "../../Components/Modal/Modal";
import {
  complaintTakeover,
  fixComplaints,
  updateComplaints,
} from "../../Redux/complaints/action";
import { dateAndTimeConverter } from "../../Utils/constants";
import { ArrowIcon, ImageFallbackIcon } from "../../Assets/assets";
import AudioPlayer from "../../Components/AudioPlayer/AudioPlayer";
import { isValidArray, isValidObject } from "../../Services/validators";
import "./SelectedComplaint.css";

function SelectedComplaint(props) {
  const [selectedComplaint, setSelectedComplaint] = useState(null);
  const [showTakeoverModal, setShowTakeoverModal] = useState(false);
  const [uploadModalData, setUploadModalData] = useState({
    show: false,
    type: "upload",
  });
  const { complaintId } = useParams();
  const [file, setFile] = useState({
    image: [],
    audio: null,
  });
  const [croppedImage, setCroppedImage] = useState([]);
  const [showImage, setShowImage] = useState(null);
  const [clearAudio, setClearAudio] = useState(false);
  useEffect(() => {
    if (props.complaints.data && props.complaints.data[complaintId]) {
      setSelectedComplaint(props.complaints.data[complaintId]);
    } else {
      props.navigate("/complaints");
    }
    // eslint-disable-next-line
  }, [props.complaints.data]);

  useEffect(() => {
    const force =
      new URLSearchParams(window.location.search)?.get("forceUpdate") || null;
    if (force === "true") {
      setUploadModalData({ show: true, type: "update" });
    }
    // eslint-disable-next-line
  }, [window.location]);

  const data = [
    {
      title: "ISSUE",
      value: selectedComplaint?.category,
    },
    ...(isValidArray(selectedComplaint?.requiredRemarks)
      ? [
          {
            title: "REMARKS",
            value: (
              <>
                {selectedComplaint?.requiredRemarks.map((data, index) => (
                  <div
                    className={`${
                      selectedComplaint?.requiredRemarks.length - 1 !== index
                        ? "padding-bottom-small"
                        : ""
                    }`}
                  >
                    {index + 1} {". "} {data.value}
                  </div>
                ))}{" "}
              </>
            ),
          },
        ]
      : []),
    ...(selectedComplaint?.remarks
      ? [{ title: "DESCRIPTION", value: selectedComplaint?.remarks }]
      : []),
    {
      title: "ISSUE RAISED ON",
      value: `${dateAndTimeConverter(
        selectedComplaint?.createdAt,
        "Time"
      )} , ${dateAndTimeConverter(selectedComplaint?.createdAt, "cardDate")}`,
    },
    {
      title: "COMPLAINT ID",
      value: selectedComplaint?.documentId,
    },
    {
      title: "LOCATION",
      value: `${selectedComplaint?.location?.PCTNo?.toString().padStart(2, "0")}
      - ${selectedComplaint?.location?.address}`,
    },
  ];

  function isTakeOvered() {
    if (
      selectedComplaint?.takenOverBy?.employeeId ===
      props.profile.data.employeeId
    ) {
      return true;
    } else {
      return false;
    }
  }
  useEffect(() => {
    if (clearAudio) {
      setFile({ ...file, image: file.image, audio: null });
    }
    // eslint-disable-next-line
  }, [clearAudio]);

  return (
    <>
      <div className="inherit-parent-height">
        <Header
          title="Complaint Details"
          backOnClick={() => {
            props.navigate("/complaints");
          }}
        />
        <div className="remaining-body-height inherit-parent-width display-flex flex-justify-content-center ">
          <div className=" inherit-parent-height inherit-parent-width max-width-500px flex-justify-content-space-between flex-direction-column">
            <div
              style={{ height: "calc(100% - 76px" }}
              className="padding-large overflow-x-scroll hide-scrollbar"
            >
              {data.map((data, index) => (
                <div key={index} className="padding-bottom-larger">
                  <div className=" font-size-small padding-bottom-default">
                    {data.title}
                  </div>

                  <div className="font-size-medium">{data.value}</div>
                </div>
              ))}

              <div className=" font-size-small padding-bottom-default">
                IMAGE
              </div>

              {isValidArray(selectedComplaint?.proof?.images) ? (
                <div className=" inherit-parent-width display-flex flex-wrap ">
                  {selectedComplaint.proof.images.map((url, index) => (
                    <div
                      key={index}
                      className={`height-width-56px margin-bottom-default ${
                        selectedComplaint.proof.images.length !== index + 1
                          ? "margin-right-default"
                          : " "
                      } `}
                      onClick={() => {
                        setShowImage(url);
                      }}
                    >
                      <img
                        className="border-radius-default inherit-parent-height inherit-parent-width"
                        data-cy="proof-image"
                        src={url}
                        alt="images"
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <div
                  className="height-fit-content width-fit-content border-radius-default margin-right-default margin-bottom-default"
                  data-cy="proof-image-fallBack"
                >
                  <div className="height-width-56px border-radius-default inherit-parent-height inherit-parent-width background-color-grey display-flex flex-justify-content-center flex-align-items-center">
                    <ImageFallbackIcon />
                  </div>
                </div>
              )}

              {selectedComplaint?.proof?.audio && (
                <>
                  <div className=" font-size-small padding-top-large padding-bottom-default">
                    AUDIO
                  </div>
                  <AudioPlayer
                    className=" max-width-500px"
                    src={
                      selectedComplaint?.proof?.audio
                        ? selectedComplaint.proof.audio
                        : " "
                    }
                  />
                </>
              )}

              <div className="padding-top-larger">
                <div
                  data-cy="undertaken-by-field"
                  className=" font-size-small padding-bottom-default"
                >
                  UNDERTAKEN BY
                </div>
                {isValidObject(selectedComplaint?.takenOverBy) ? (
                  <div className="display-flex">
                    <div className="font-size-medium">
                      {selectedComplaint.takenOverBy?.phoneNumber ||
                        selectedComplaint.takenOverBy?.email}
                    </div>{" "}
                    ,{" "}
                    <div className="font-size-medium">
                      {selectedComplaint.takenOverBy?.employeeName ||
                        selectedComplaint.takenOverBy?.email
                          ?.split("@")?.[0]
                          .replace(/\d/g, "")}
                    </div>
                  </div>
                ) : (
                  <div className="font-size-medium">
                    No one has undertaken this compliant
                  </div>
                )}
              </div>
              <div className="padding-top-larger padding-bottom-larger">
                <div className=" font-size-small padding-bottom-default">
                  UPDATES
                </div>
                {selectedComplaint?.updates ? (
                  <>
                    <UpdateCard
                      data={
                        selectedComplaint?.updates?.[
                          selectedComplaint?.updates?.length - 1
                        ]
                      }
                      onCardClick={() => {
                        props.navigate(
                          `/viewUpdates/${selectedComplaint.documentId}`
                        );
                      }}
                    />
                  </>
                ) : (
                  <div className="font-size-medium">No updates Available</div>
                )}
              </div>
            </div>

            {isTakeOvered() && (
              <>
                <div className="display-flex flex-justify-content-center font-size-small padding-top-default padding-bottom-default">
                  <div>Want to create a new update ? </div>
                  <div
                    data-cy="update-compliant-button"
                    className="font-color-primary padding-left-default cursor-pointer"
                    onClick={() => {
                      setUploadModalData({ show: true, type: "update" });
                    }}
                  >
                    Create
                  </div>
                </div>
                <div className="flex-center-children-vertically margin-left-large margin-right-large padding-bottom-default">
                  <hr
                    style={{ width: "100%" }}
                    className="display-inline-block border-color-secondary-lite border-top-left-right-none flex-basis-1 "
                  />
                  <span className=" margin-horizontal-default display-inline-block font-color-secondary font-size-small">
                    OR
                  </span>
                  <hr
                    style={{ width: "100%" }}
                    className="display-inline-block border-color-secondary-lite border-top-left-right-none flex-basis-1 "
                  />
                </div>
              </>
            )}

            {!selectedComplaint?.takenOverBy || isTakeOvered() ? (
              <>
                <div className=" inherit-parent-width display-flex flex-justify-content-center padding-left-large padding-right-large padding-bottom-large background-white">
                  <Button
                    loading={props.complaints.loading}
                    type="button"
                    className=" max-width-500px"
                    data-cy={`${
                      isTakeOvered() ? "issue-fixed" : "takeover"
                    }-button`}
                    text={isTakeOvered() ? "Issue Fixed" : "Takeover"}
                    onClick={() => {
                      if (isTakeOvered()) {
                        setUploadModalData({ show: true, type: "upload" });
                      } else {
                        setShowTakeoverModal(true);
                      }
                    }}
                    boxShadow={false}
                  />
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        <FileUploadModal
          showModal={uploadModalData?.show}
          proof={{
            required: true,
          }}
          isUpdate={uploadModalData.type === "update"}
          type="compliant"
          setFile={setFile}
          file={file}
          clearAudio={clearAudio}
          setClearAudio={setClearAudio}
          RemoveImageOnClick={(data) => {
            setCroppedImage(
              Object.values(croppedImage).filter((value) => value.name !== data)
            );
          }}
          tempSrc={croppedImage}
          closeModal={() => {
            setUploadModalData({
              show: false,
              type: "upload",
            });
          }}
          loading={props.complaints.loading}
          continueOnClick={(data) => {
            if (uploadModalData.type === "update") {
              props.updateComplaints(
                data.updates,
                complaintId,
                selectedComplaint?.locationId,
                {
                  image: croppedImage,
                  audio: file.audio,
                }
              );
              setUploadModalData({
                show: false,
                type: "upload",
              });
              setCroppedImage([]);
              setFile({
                image: [],
                audio: null,
              });
            } else {
              props.fixComplaints(
                complaintId,
                selectedComplaint.locationId,
                props.profile.data.employeeId,
                {
                  image: croppedImage,
                  audio: file.audio,
                },
                props.navigate
              );
              setUploadModalData({
                show: false,
                type: "upload",
              });
              setCroppedImage([]);
              setFile({
                image: [],
                audio: null,
              });
            }
          }}
          disabled={isValidArray(croppedImage) || file.audio ? false : true}
        />
        <Modal
          show={showTakeoverModal}
          canIgnore={true}
          onClose={() => {
            setShowTakeoverModal(!showTakeoverModal);
          }}
          width="inherit-parent-width"
          position=" absolute-center-self-horizontally bottom-0 "
          maxWidth="max-width-630px"
          background="false"
          boxShadow="false"
          borderRadius="false"
          height="height-fit-to-content"
        >
          <section
            data-cy="takeover-modal"
            className="font-color-secondary font-family-gilroy-medium font-color-secondary text-align-center padding-larger background-white"
          >
            <div className=" font-family-RHD-medium font-size-medium">
              TAKEOVER
            </div>
            <div className=" padding-top-default">
              Are you sure you want to take over the complaint?
            </div>

            <section className="display-flex padding-top-larger ">
              <Button
                boxShadow={false}
                type="submit"
                variant="primary"
                text="Yes"
                className={"border-1px-e5e5e5 margin-right-small"}
                onClick={() => {
                  props.complaintTakeover(complaintId, {
                    employeeId: props.profile.data.employeeId,
                    employeeName: props.profile.data.name,
                    phoneNumber: props.profile.data.phoneNumber,
                  });
                  setShowTakeoverModal(false);
                }}
                data-cy={"yes-takeover-button"}
              />
              <Button
                boxShadow={false}
                text="No"
                variant={"secondary"}
                onClick={() => {
                  setShowTakeoverModal(false);
                }}
                className={
                  "font-color-secondary border-1px-e5e5e5 margin-left-small"
                }
                data-cy={"no-takeover-button"}
              />
            </section>
          </section>
        </Modal>
        <Modal
          show={isValidArray(file?.image)}
          canIgnore={true}
          onClose={() => {
            setFile({ ...file, image: [] });
          }}
          width="inherit-parent-width"
          maxWidth="max-width-800px"
          background="false"
          boxShadow="false"
          borderRadius="false"
          height="inherit-parent-height max-height-500px"
        >
          <div
            data-cy="cropper-modal"
            className={`background-white inherit-parent-height border-radius-default box-shadow-default font-family-gilroy-regular font-color-secondary`}
          >
            <CropperModal
              className="border-radius-default"
              OnBlobCreate={(blob) => {
                const imageFile = new File([blob], `${+new Date()}.png`, {
                  type: "image/png",
                });
                setCroppedImage([...croppedImage, imageFile]);
              }}
              file={file.image}
              setFile={() => {
                setFile({ ...file, image: [] });
              }}
            />
          </div>
        </Modal>
        <Modal
          show={typeof showImage === "string"}
          canIgnore={true}
          onClose={() => {
            setShowImage(null);
          }}
          width="inherit-parent-width"
          maxWidth="inherit-parent-width"
          background="false"
          boxShadow="false"
        >
          <div
            data-cy="image-view-modal"
            className={`inherit-parent-height font-family-gilroy-regular font-color-secondary`}
          >
            <img
              className="inherit-parent-width"
              data-cy="view-proof-image"
              src={showImage}
              alt="images"
            />
          </div>
        </Modal>
      </div>
    </>
  );
}

const mapStateToProps = function (state) {
  return {
    complaints: state.complaints,
    profile: state.profile,
    locations: state.locations,
  };
};

const mapDispatchToProps = function () {
  return {
    fixComplaints: (complaintId, locationId, employeeId, proof, navigate) =>
      fixComplaints(complaintId, locationId, employeeId, proof, navigate),
    updateComplaints: (remarks, complaintId, locationId, proof) =>
      updateComplaints(remarks, complaintId, locationId, proof),
    complaintTakeover: (complaintId, employeeData) =>
      complaintTakeover(complaintId, employeeData),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SelectedComplaint);

const UpdateCard = (props) => {
  return (
    <div
      data-cy={"compliant-update-view-clicker"}
      className="display-flex flex-justify-content-space-between flex-align-items-center"
      onClick={props.onCardClick}
    >
      <div>
        <div className="font-size-medium">{props.data?.remarks}</div>
        <div className="font-size-small padding-top-small">
          {`${dateAndTimeConverter(
            props.data?.updatedAt,
            "Time"
          )} , ${dateAndTimeConverter(props.data?.updatedAt, "cardDate")}`}
        </div>
      </div>
      <div>
        <ArrowIcon />
      </div>
    </div>
  );
};
